import request from '@/utils/request'

// 查询设备信息列表
export function listDevice(query) {
  return request({
    url: '/valve/valve-device/list',
    method: 'get',
    params: query
  })
}

// 查询设备信息分页
export function pageDevice(query) {
  return request({
    url: '/valve/valve-device/page',
    method: 'get',
    params: query
  })
}

// 查询设备信息详细
export function getDevice(data) {
  return request({
    url: '/valve/valve-device/detail',
    method: 'get',
    params: data
  })
}

// 新增设备信息
export function addDevice(data) {
  return request({
    url: '/valve/valve-device/add',
    method: 'post',
    data: data
  })
}

// 修改设备信息
export function updateDevice(data) {
  return request({
    url: '/valve/valve-device/edit',
    method: 'post',
    data: data
  })
}

// 删除设备信息
export function delDevice(data) {
  return request({
    url: '/valve/valve-device/delete',
    method: 'post',
    data: data
  })
}

// 设备绑定用户
export function bindDevice(data) {
  return request({
    url: '/valve/valve-device/bind',
    method: 'post',
    data: data
  })
}

// 设备 导入
export function importData(data) {
  return request({
    url: '/valve/valve-device/import',
    method: 'post',
    data
  })
}
