import request from '@/utils/request'

// 查询项目信息列表
export function listProject(query) {
  return request({
    url: '/valve/valve-project/list',
    method: 'get',
    params: query
  })
}

// 查询项目信息分页
export function pageProject(query) {
  return request({
    url: '/valve/valve-project/page',
    method: 'get',
    params: query
  })
}

// 查询项目信息详细
export function getProject(data) {
  return request({
    url: '/valve/valve-project/detail',
    method: 'get',
    params: data
  })
}

// 新增项目信息
export function addProject(data) {
  return request({
    url: '/valve/valve-project/add',
    method: 'post',
    data: data
  })
}

// 修改项目信息
export function updateProject(data) {
  return request({
    url: '/valve/valve-project/edit',
    method: 'post',
    data: data
  })
}

// 删除项目信息
export function delProject(data) {
  return request({
    url: '/valve/valve-project/delete',
    method: 'post',
    data: data
  })
}
